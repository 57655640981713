<template>
  <div>
    <div class="page-content text-color-1">
      <div v-if="guestNotFound" class="alert alert-danger">
        Convite não encontrado. Por favor confirme o URL.
      </div>
      <div
        v-if="formSubmitResult == 'success'"
        class="alert alert-success"
        @click="dismissFormResult"
      >
        Formulário submetido com sucesso.
      </div>
      <div class="svg-ancora wow swing">
        <img loading="lazy" src="@/assets/svg/ancora.svg" alt="ancora" />
      </div>

      <div class="card-container stamp-container">
        <div class="card">
          <p v-if="guest" class="font-josefin-sans text-color-2 text-ola">
            Olá {{ guest.name }},
          </p>

          <div class="svg-dar-o-no">
            <img
              loading="lazy"
              src="@/assets/svg/dar-o-no.svg"
              alt="nó marítimo"
            />
          </div>

          <h1 class="font-villa-didot text-dar-o-no">
            Vamos <span class="nowrap">dar o nó</span>
          </h1>

          <div class="text-convite text-color-2 text-decorated-center">
            convite para
          </div>
          <div class="text-casamento font-villa-didot">Casamento</div>

          <div class="text-eduarda-joao font-bentham">Eduarda &amp; João</div>

          <div class="text-de text-decorated-center">E</div>

          <div class="text-casamento font-villa-didot">Batizado</div>
          <div class="text-eduarda-joao font-bentham">Gonçalo</div>

          <!--<span class="uncancelled-stamp">nova data</span>-->
          <div class="details">
            <div class="details__day">
              <div class="font-bentham">Sábado</div>
              <div class="text-data font-villa-didot text-color-2">
                05.10.2024
              </div>
            </div>
            <div class="details__church">
              <div class="text-igreja font-bentham">
                <a
                  href="https://goo.gl/maps/SbjibyWfVKLr5Bxm8"
                  rel="noreferrer noopener nofollow"
                  target="_blank"
                  >Igreja de Recarei</a
                >
              </div>
              <div class="text-igreja-nome">em Recarei, Paredes</div>
            </div>
            <div class="details__time">
              <div class="text-hora font-bentham">Hora</div>
              <div class="text-horario font-villa-didot text-color-2">
                12:00
              </div>
            </div>
          </div>

          <div class="text-quinta font-bentham text-color-3">
            O copo de água será na
            <a
              href="https://maps.app.goo.gl/8wnrK2M5Jor6Rq5z6"
              rel="noreferrer noopener nofollow"
              target="_blank"
              >Quinta O Campo</a
            >
            em Freamunde, Paços de Ferreira
          </div>

          <div class="text-confirmar text-color-2">
            Confirmar até 15.08.2024
          </div>

          <template v-if="guest">
            <template v-if="guest.reply == 'no-reply'">
              <button
                id="button-modal-embarcar"
                class="button-primary font-bentham text-color-2"
                @click="showModalEmbarcar = true"
              >
                Embarcar
              </button>
              <button
                id="button-modal-terra"
                class="button-secondary font-bentham"
                @click="showModalEmTerra = true"
              >
                Ficar em terra
              </button>
            </template>
            <template v-if="guest.reply == 'going'">
              <p>
                Presença <span class="text-color-3">confirmada</span>. Até
                breve.
              </p>
            </template>
            <template v-if="guest.reply == 'not-going'">
              <p>Ausência confirmada.</p>
            </template>
          </template>
        </div>
        <!-- // card -->
      </div>
    </div>

    <gallery />

    <directions />

    <invite-modals
      v-if="guest && guest.reply == 'no-reply'"
      :show-modal-embarcar="showModalEmbarcar"
      :show-modal-em-terra="showModalEmTerra"
      :guest="guest"
      @close-modal-embarcar="showModalEmbarcar = false"
      @close-modal-em-terra="showModalEmTerra = false"
    />

    <div class="seagull wow jello fadeInRight"></div>
    <div class="seagull seagull-2 wow jello fadeInLeft"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import InviteModals from '@/components/InviteModals.vue';
import Gallery from '@/components/Gallery.vue';
import Directions from '@/components/Directions.vue';
import '@/models/guest-data';
import jsonData from '@/assets/invites.json';

@Component({
  components: {
    InviteModals,
    Gallery,
    Directions,
  },
})
export default class Invite extends Vue {
  @Prop({ default: true })
  public showGuest!: boolean;

  public guestNotFound: boolean = false;
  public showModalEmbarcar: boolean = false;
  public showModalEmTerra: boolean = false;

  private invites = jsonData as { [key: string]: GuestData };

  public mounted() {
    this.$nextTick(function () {
      // Code that will run only after the entire view has been rendered.

      if (this.formSubmitResult) {
        setTimeout(() => this.dismissFormResult(new Event('timeout')), 5000);
      }
    });
  }

  get guest(): GuestData | null {
    if (!this.showGuest) {
      this.guestNotFound = false;
      return null;
    }

    // this is a computed property, see:
    // https://vuejs.org/v2/guide/computed.html
    // "A computed property will only re-evaluate when some of its reactive
    // dependencies have changed."
    // If I understood this correctly it will only compute the property when
    // `$route` or `invites` changes.

    const inviteId: string = this.$route.params.id;
    if (!!inviteId && inviteId in this.invites) {
      const guestData = this.invites[inviteId];
      this.guestNotFound = false;
      return guestData;
    } else {
      this.guestNotFound = true;
      return null;
    }
  }

  get formSubmitResult(): false | 'success' | 'failure' {
    const submitVal = this.$route.query['form-result'];
    if (submitVal === 'success' || submitVal === 'failure') {
      return submitVal;
    } else {
      return false;
    }
  }

  public dismissFormResult(event: Event) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        ['form-result']: undefined,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}

.svg-dar-o-no {
  margin-top: 30px;
  margin-bottom: 30px;
}

.svg-dar-o-no > img {
  height: 36px;
}

.svg-ancora {
  z-index: 1;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1%);
  }
  100% {
    transform: translateY(0);
  }
}

.svg-ancora > img {
  backface-visibility: hidden;
  height: 185px;
  filter: drop-shadow(0 1px 1px #fff) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
  animation: float;
  animation-duration: 0.85s;
  animation-iteration-count: infinite;
}

.text-ola,
.text-convite,
.text-de {
  font-size: 18px;
}

.text-ola {
  line-height: 1.2;
}

.text-dar-o-no {
  font-size: 24px;
}

.text-convite,
.text-de {
  font-size: 18px;
  line-height: 1;
}

.text-casamento {
  font-size: 80px;
  line-height: 1;
}

.text-eduarda-joao {
  font-size: 64px;
  line-height: 1;
}

.text-confirmar {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: underline;
}

a::after {
  content: '⚓';
  font-size: 62%;
  vertical-align: baseline;
}

.details > div:first-child > div:first-child,
.details > div:last-child > div:first-child {
  font-size: 24px;
}

.details > div:first-child > div:last-child,
.details > div:last-child > div:last-child {
  font-size: 24px;
}

.details > div:nth-child(2) > div:first-child {
  font-size: 18px;
}

.details > div:nth-child(2) > div:last-child {
  font-size: 15px;
}

.details > div:first-child,
.details > div:last-child {
  flex-basis: 200px;
}
.details > div:nth-child(2) {
  flex-grow: 1;
  // border-left: 1px dotted #626966;
  // border-right: 1px dotted #626966;

  border-left: 1px dotted #bbb;
  border-right: 1px dotted #bbb;
}

.text-decorated-center,
.details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.details > div:first-child,
.details > div:last-child {
  flex-grow: 1;
}

.details > div {
  padding: 0 15px;
}

.text-decorated-center::before,
.text-decorated-center::after {
  content: '';
  display: block;
  flex-grow: 1;
}

.text-decorated-center::before {
  margin-right: 15px;
}

.text-decorated-center::after {
  margin-left: 15px;
}

.details,
.text-decorated-center::before,
.text-decorated-center::after,
.text-quinta,
.text-confirmar {
  // border-top: 1px dotted #626966;
  border-top: 1px dotted #bbb;
}

@media only screen and (max-width: 800px) {
  .card {
    padding: 64px 24px;
  }
}

.text-casamento {
  padding: 12px 0;
  font-size: 9vw;
}

@media only screen and (min-width: 768px) {
  .text-casamento {
    font-size: 80px;
  }
}

.text-eduarda-joao {
  padding: 6px 0 12px 0;
  font-size: 8vw;
}

@media only screen and (min-width: 768px) {
  .text-eduarda-joao {
    font-size: 64px;
  }
}

.details > div {
  margin-bottom: 12px;
  margin-top: 12px;
}

.text-quinta {
  padding: 12px 0;
}

.text-confirmar {
  padding-top: 24px;
}

@media only screen and (max-width: 720px) {
  .details {
    flex-direction: column;
  }

  .details > div {
    flex-basis: 0 !important;
  }

  .details > div:nth-child(2) {
    // border: 1px dotted #626966;
    // border: 1px dotted #bbb;
    border: none;
    // border-width: 1px 0;
    width: 100%;
    // padding: 12px 0;
    padding: 0;
  }
}

.cancelled {
  text-decoration: line-through;
}

.stamp-container {
  position: relative;
}

.cancelled-stamp {
  transform: rotate(-15deg);
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  mask-image: url('~@/assets/images/grunge.png');
  mask-size: 944px 604px;
  mix-blend-mode: multiply;
  color: #d23;
  border: 0.5rem double #d23;
  mask-position: 2rem 3rem;
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1em;
}

.uncancelled-stamp {
  transform: rotate(-15deg);
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  mask-image: url('~@/assets/images/grunge.png');
  mask-size: 944px 604px;
  mix-blend-mode: multiply;
  color: #96a53c;
  border: 0.5rem double #96a53c;
  mask-position: 2rem 3rem;
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1em;
}
</style>
